/* Custom */

body {
    /*background-image: url('./assets/images/png/BackgroundContent.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 500% 500% !important;*/
}

#home {
    margin-top: 95px;
    background-image: url('./assets/images/backgroundImages/daas.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    padding: 20px;
    height: calc(100vh);
}

#main, #about, #technology {
    margin: 20px auto;
    /*background-image: url('./assets/images/png/BackgroundContent.png');
    background-repeat: no-repeat;
    background-position: center center;*/
}

/*#about, #technology {
    margin: 20px auto;
    background-image: none;
}*/

/*#funded {
    background-image: url('./assets/images/png/BackgroundContent.png');
    background-repeat: no-repeat;
    background-position: center;
}*/

#funded > div > div > div {
    width: 75% !important;
    margin: auto;
    padding: 20px;
}

#funded > div > div > div > div > div {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000;
}

.funded--images--main {
    display: table-row;
    text-align: center;
}

.funded--images--container {
    width: 100px;
    height: 100px;
    display: table-cell;
}

.funded--images--main > .funded--images--container:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
}

.funded--images {
    width: 100px;
    height: 100px;
    object-fit: contain;
    background-color: #fff;
}

.navbar {
    background-color: #0E1451 !important;
}

#footer {
    background-image: url('./assets/images/png/Background.png');
    background-repeat: no-repeat;
    background-position: center;
}

#error {
    color: #000 !important;
    margin-top: 100px;
}

.design--main--content {
    padding: 20px 40px;
    color: #000 !important;
}

.design--main--content > div {
    padding: 10px 0px;
}

.design--main--content > .title-text {
    font-weight: bold;
}

.design--main--content > .screen-image {
    max-width: 400px;
    margin: auto;
}

.design--main--content > .screen-image > img {
    max-width: 400px;
    object-fit: cover;
}

.design--main--headline {
    position: relative;
    float: left;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: calc(100vh);
    color: #000;
    font-weight: 600;
    font-size: 24px;
}

nav {
    background-image: url('./assets/images/png/BackgroundNavigationBar.png');
    background-repeat: no-repeat;
    background-position: center;
    /*background-size: cover;*/
    background-color: #0E1451;
    padding: 15px;
}

.footer {
    color: #5BC5EC !important;
    width: 100%;
    /*margin: auto;*/
    text-align: center;
    font-size: 14px !important;
}

@media screen and (max-width: 767px){
    #home-mobile {
        margin-top: 95px !important;
        background-image: url('./assets/images/backgroundImages/daas.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
        /* background-attachment: fixed !important; */
        max-height: 400px !important;
    }

    .design--main--content > .screen-image {
        max-width: 200px;
        margin: auto;
    }

    .design--main--content > .screen-image > img {
        max-width: 200px;
        object-fit: cover;
    }
}

@media screen and (max-width: 500px){
    #home-mobile {
        max-height: 300px !important;
    }
    #main {
        background-size: auto 100%;
    }
}

@media screen and (max-width: 400px){
    #home-mobile {
        max-height: 250px !important;
    }
}

@media screen and (min-width: 521px) and (max-width: 978px){
    #funded > div > div > div > div > div:first-child {
        /*margin: 10px auto;*/
        width: 200px;
        color: #000;
    }
}

@media screen and (min-width: 401px) and (max-width: 520px){
    #funded > div > div > div > div > div:first-child {
        /*margin: 10px auto;*/
        width: 200px;
        color: #000;
    }

    .funded--images--container {
        width: 60px;
        height: 60px;
        display: table-cell;
    }

    .funded--images--main > .funded--images--container:nth-child(2) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .funded--images {
        width: 60px;
        height: 60px;
        object-fit: contain;
        background-color: #fff;
    }
}

@media screen and (min-width: 301px) and (max-width: 400px){
    #funded > div > div > div {
        width: 100% !important;
    }

    #funded > div > div > div > div > div:first-child {
        /*margin: 10px auto;*/
        width: 200px;
        color: #000;
    }

    .funded--images--container {
        width: 60px;
        height: 60px;
        display: table-cell;
    }

    .funded--images--main > .funded--images--container:nth-child(2) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .funded--images {
        width: 60px;
        height: 60px;
        object-fit: contain;
        background-color: #fff;
    }
}

@media screen and (max-width: 300px){
    #funded > div > div > div {
        width: 100% !important;
    }

    #funded > div > div > div > div > div:first-child {
        /*margin: 10px auto;*/
        width: auto;
        color: #fff;
    }

    .funded--images--container {
        width: 60px;
        height: 60px;
        display: table-cell;
    }

    .funded--images--main > .funded--images--container:nth-child(2) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .funded--images {
        width: 60px;
        height: 60px;
        object-fit: contain;
        background-color: #fff;
    }
}

/* Default */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
